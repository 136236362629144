<template>
  <div class="dashboard-wrapper pt-4 pb-4 pl-2 pr-2">
    <Row :gutter="16" class="mx-0" justify="center" type="flex">
      <Col id="orders-list" :sm="22" :xl="7">
        <OrdersList
          :open-orders-list="openOrdersList"
          :openOrdersLoading="ordersListLoading"
          :preconditions-list="preconditionOrdersList"
          :preconditionsLoading="preconditionsListLoading"
          class="mb-6"
          @getOrders="getOrders"
        />
      </Col>

      <Col :sm="22" :xl="17">
        <Assets
          :excoino-assets="excoinoAssets"
          :excoino-list-loading="excoinoListLoading"
          :market-assets="marketAssets"
          :market-list-loading="marketListLoading"
          class="mb-6"
        />

        <Trends
          :hot-reports="hotReports"
          :loading="trendsLoading"
          :newest-markets="newestMarkets"
        />

        <FavMarkets
          :loading="marketsLoading"
          :marketsList="marketsList"
          class="mb-6"
          @refreshMarkets="getFavoriteMarkets"
        />
      </Col>
    </Row>
  </div>
</template>

<script>
import SockJS from "sockjs-client";
import WebStomp from "stompjs";
const OrdersList = () => import("@/components/Dashboard/OrdersList")
const Assets = () => import("@/components/Dashboard/Assets")
const Trends = () => import("@/components/Dashboard/TrendsSection")
const FavMarkets = () => import("@/components/Dashboard/FavoriteMarkets")
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    OrdersList,
    Assets,
    Trends,
    FavMarkets
  },
  data() {
    return {
      marketAssets: {},
      excoinoAssets: [],
      openOrdersList: [],
      preconditionOrdersList: [],
      newestMarkets: [],
      hotReports: [],
      marketsList: [],
      ordersListLoading: false,
      preconditionsListLoading: false,
      marketListLoading: false,
      excoinoListLoading: false,
      trendsLoading: false,
      marketsLoading: false,
      stompClient: null,
    }
  },
  created() {
    this.setNavigation("nav-dashboard");
    this.getMarketWalletList();
    this.getExcoinoWalletsList();
    this.getOpenOrdersList();
    this.getMarketReports();
    this.getFavoriteMarkets();
    this.initWebSocket();
  },
  beforeDestroy() {
    this.stompClient.disconnect();
  },
  computed: {
    ...mapGetters({
      getMember: "global/MEMBER",
    }),
    member() {
      return this.getMember;
    },
  },
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getMarketWallets: "userCenter/TOP5_WALLETS_SERVICE",
      getExcoinoWallets: "userCenter/EXCOINO_WALLETS_SERVICE",
      getOpenOrders: "exchange/GET_TOP_CURRENT_ORDERS_SERVICE",
      getPreconditionOrders: "exchange/GET_TOP_PRECONDITION_ORDERS_SERVICE",
      getTrends: "market/GET_TRENDS_REPORT",
      getFavorites: "exchange/GET_DASHBOARD_FAVORITE_MARKETS_SERVICE",
    }),
    getOrders(key) {
      if (key === "current") this.getOpenOrdersList();
      else this.getPreconditionOrdersList();
    },
    getMarketWalletList() {
      this.marketListLoading = true;
      this.getMarketWallets()
        .then(async response => {
          this.marketAssets = await response.data.data;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.marketListLoading = false;
        })
    },
    getExcoinoWalletsList() {
      this.excoinoListLoading = true;
      this.getExcoinoWallets()
        .then(async response => {
          this.excoinoAssets = await response.data.data;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.excoinoListLoading = false;
        })
    },
    getOpenOrdersList() {
      this.openOrdersList = [];
      this.ordersListLoading = true;

      this.getOpenOrders().then(async response => {
        this.openOrdersList = await response.data.data.content;
        this.openOrdersList.forEach((row) => {
          row.price =
            row.type === "MARKET_PRICE"
              ? this.$t("exchange.marketPrice")
              : row.price;
        });
      })
        .finally(() => {
          this.ordersListLoading = false;
        })
    },
    getPreconditionOrdersList() {
      this.preconditionOrdersList = [];
      this.preconditionsListLoading = true;

      this.getPreconditionOrders().then(async response => {
        this.preconditionOrdersList = await response.data.data.content;
      })
        .finally(() => {
          this.preconditionsListLoading = false;
        })
    },
    getMarketReports() {
      this.trendsLoading = true;
      this.getTrends()
        .then(async response => {
          const trends = await response.data.data;
          this.newestMarkets = trends.newestMarketsList;
          trends.hotReports && Object.keys(trends.hotReports).forEach(key => {
            this.hotReports.push({
              type: key,
              ...trends.hotReports[key]
            })
          })
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.trendsLoading = false;
        })
    },
    getFavoriteMarkets() {
      this.marketsList = [];
      this.marketsLoading = true;
      this.getFavorites().then(async response => {
        this.marketsList = await response.data.data;
      })
        .finally(() => {
          this.marketsLoading = false;
        })
    },
    initWebSocket() {
      const socket = new SockJS(process.env.VUE_APP_API_BASE_URL + "/market/market-ws");
      this.stompClient = WebStomp.over(socket);
      this.stompClient.debug = () => {};
      this.stompClient.connect({}, this.onWebSocketConnect);
    },
    onWebSocketConnect() {
      this.stompClient.subscribe("/topic/market/order-canceled/*/*/" + this.member.id,
        () => {
          this.getOpenOrdersList();
          this.getPreconditionOrdersList();
        });

      this.stompClient.subscribe("/topic/market/order-completed/*/*/" + this.member.id,
        () => {
          this.getOpenOrdersList();
          this.getPreconditionOrdersList();
        });

      this.stompClient.subscribe("/topic/market/order-trade/*/*/" + this.member.id,
        () => {
          this.getOpenOrdersList();
          this.getPreconditionOrdersList();
        });

      this.stompClient.subscribe("/topic/market/thumb",
        (msg) => {
          this.handleMarketsSocketData(JSON.parse(msg.body));
        });
    },
    handleMarketsSocketData(data) {
      this.marketsList.forEach(elem => {
        if (data.symbol === elem.symbol) {
          elem.close = data.close;
          elem.chg = data.chg;
          elem.high = data.high;
          elem.low = data.low;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "Dashboard.scss";
</style>